<template>
  <div class="container">
    <div class="main" v-loading="loading">
      <left-menu
        class="left"
        :left="leftData"
        :key="leftKey"
        :delTag="delTag"
        @childByValue="getLeftFilter"
      />
      <div class="list-container">
        <div class="list-top">
          <span>
            共
            <span class="total-count">{{ page.total }}</span>
            条记录
          </span>
          <!-- <div class="top-actions">
            <el-checkbox
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="selectAll"
            >本页全选</el-checkbox>
            <el-button type="primary" v-if="canAdd" @click="batchAdd">批量收藏到书单</el-button>
          </div> -->
        </div>
        <div class="sort-search" v-if="loaded">
          <sort-buttons :sortFilters="orderActions" @change-sort="changeSort" />
          <search-input :key="searchKey" :haveOption="false" @do-search="doSearch" />
        </div>
        <no-content class="no-content" v-if="emptyList" />
        <div class="item-list" v-else>
          <div class="item-box" v-for="(item, index) in bookList" :key="item.ruid">
            <div class="left-box">
              <p>{{ index + 1 + page.pageno * page.pagesize }}</p>
              <!-- <el-checkbox
                v-model="selected"
                :label="item.id"
                @change="changeSelect"
              /> -->
            </div>
            <div class="detail">
              <book-detail :book="item" :rows="dataListRows" />
              <!-- <div class="buttons">
                <el-button
                  v-for="button in item.recordaction"
                  :key="button.ruid"
                  :type="button.type"
                  @click="buttonClick(button, index)"
                >{{ button.text }}</el-button>
              </div> -->
            </div>
          </div>
          <list-pager :page="page" @change-page="changePage" />
        </div>
      </div>
    </div>
    <add-to-list
      :showDialog="showDialog"
      :items="addItems"
      @close-export="showDialog = false"
      @save-list="saveList"
    />
  </div>
</template>

<script>
import LeftMenu from 'components/LeftMenu'
import SortButtons from 'components/SortButtons'
import SearchInput from 'components/SearchInput'
import BookDetail from 'components/DataBookDetail'
import ListPager from 'components/ListPager'
import NoContent from 'components/NoContent'
import AddToList from './AddToList'

import rows from 'assets/js/detailRows'
import { dataListRows } from 'assets/js/dataRows'

export default {
  components: {
    LeftMenu,
    SortButtons,
    SearchInput,
    BookDetail,
    ListPager,
    NoContent,
    AddToList,
  },
  name: 'DataBookList',
  props: {
    unitId: String,
  },
  data() {
    return {
      subjectGroup: null,
      page: {
        pageno: 0,
        pagesize: 10,
        total: 0,
      },
      order: {
        orderfield: '',
        ordertype: 'D',
      },
      loading: false,
      bookList: [],
      leftData: [],
      classify: [],
      leftFilter: [],
      filter: [],
      orderActions: [],
      searchOptions: [],
      delTag: null,
      detailRows: rows,
      checkAll: false,
      isIndeterminate: false,
      selected: [],
      subject: '',
      leftKey: 0,
      searchKey: 0,
      fromLeft: false,
      searching: false,
      showDialog: false,
      filterTags: [],
      addItems: [],
      dataListRows,
      loaded: false,
    }
  },
  computed: {
    emptyList() {
      const list = this.bookList
      return list.length < 1 ? true : false
    },
    canAdd() {
      const token = localStorage.getItem('token')
      if (token) return true
      return false
    },
  },
  mounted() {
    this.getListData()
  },
  methods: {
    batchAdd() {
      if (!this.selected.length) return this.$message.error('请先选择书目')
      let items = []
      this.selected.forEach((item) => {
        let target = this.bookList.find((book) => item === book.id)
        if (target) items.push({ id: target.id, ruid: target.ruid })
      })
      this.addItems = items
      this.showDialog = true
    },
    saveList() {
      this.showDialog = false
      this.selectAll = false
      this.isIndeterminate = false
      this.selected = []
      this.getListData()
    },
    buttonClick(button, index) {
      let item = this.bookList[index]
      switch (button.text) {
        case '收藏到书单':
          this.addItems = [{ id: item.id, ruid: item.ruid }]
          this.showDialog = true
          break
        case '评论':
          this.starBook(item)
          break
      }
    },
    async starBook(item) {
      this.loading = true
      let userId = localStorage.getItem('userRuid')
      // const res = await this.$http.get(`api/v1/user/personaldata/addStar?userRuid=${userId}&dataId=${item.id}`)
      const body = {
        userId,
        dataId: [item.id], //资料id，多个时逗号隔开
        type: 'USER',
        // "reason": this.choiceReason
      }
      const res = await this.$http.post('/api/v1/user/personaldata/addStar', body)
      if (res.cxajaxrc != 0) return false

      this.$message.success('评论成功')
      this.getListData()
    },
    tagClick(index) {
      let tags = this.filterTags
      this.delTag = tags[index]
    },
    changeSort(obj) {
      this.page.pageno = 0
      this.order = obj
      this.getListData()
    },
    doSearch(obj) {
      this.page.pageno = 0
      let filter = {
        logicalop: 'and',
        relationop: 'like',
        fieldname:
          '10100001,10110001,15900001,15900011,10400001,281450001,888000009,10120001',
        word: obj.value,
      }
      this.searching = true
      this.filter = filter
      this.getListData()
    },
    resetPage() {
      this.leftKey++
      this.searchKey++
      this.subject = ''
      this.classify = []
      this.leftFilter = []
      this.filter = []
      this.filterTags = []
      this.delTag = null
      this.page.pageno = 0
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false
    },
    subjectItem(item) {
      this.resetPage()
      if (!item.urlappend) {
        this.resetPage()
      } else {
        this.subject = item.urlappend
        const filter = item.filterfield[0]
        this.classify = [filter]
      }
      this.leftFilter = []
      this.leftKey++
      this.getListData()
    },
    selectAll(val) {
      this.checkAll = val
      const list = this.bookList
      if (val) this.selected = list.map((item) => item.id)
      else this.selected = []
      this.isIndeterminate = false
    },
    changeSelect() {
      const selected = this.selected.length
      const list = this.bookList.length
      if (selected === list) {
        this.isIndeterminate = false
        this.checkAll = true
      } else {
        this.checkAll = false
        if (!selected) this.isIndeterminate = false
        else this.isIndeterminate = true
      }
    },
    getLeftFilter(filter) {
      this.page.pageno = 0
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false

      let array = []
      let filterTags = []
      filter.forEach((item) => {
        if (item.filterflag == 1) {
          let postbody = {
            logicalop: 'and',
            fieldname: item.filterfield,
            relationop: 'raw',
            word: item.ruid,
          }
          array.push(postbody)
        } else if (item.filterflag == 100) {
          item.filterfield.forEach((item) => {
            array.push(item)
          })
        } else {
          filterTags.push(item)
        }
      })
      this.filterTags = filterTags

      this.filter = []
      this.searchKey++

      this.leftFilter = array
      this.fromLeft = true
      this.getListData()
    },
    changePage(page) {
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false
      this.page.pageno = page

      this.getListData()
    },
    getFilter() {
      // 合并过滤条件
      let filter = this.classify.concat(this.leftFilter)
      filter = filter.concat(this.filter)

      filter.push({
        fieldname: '53001',
        logicalop: 'and',
        relationop: '=',
        word: this.$route.query.id,
      })
      if (this.unitId) {
        filter.push({
          fieldname: '53102',
          logicalop: 'and',
          relationop: '=',
          word: this.unitId,
        })
      }

      let bodyData = {
        filter: filter,
      }
      this.fromLeft = false
      this.searching = false

      return bodyData
    },
    async getListData() {
      this.loaded = true
      this.loading = true
      // 拼接参数
      let obj = Object.assign(this.page, this.order)
      let param = this.getParams(obj)
      param += this.subject
      const bodyData = this.getFilter()

      // 发送请求
      const res = await this.$http.post(
        '/api/v1/datasearch/ebook_collection_book_list' + param,
        bodyData
      )
      if (res.cxajaxrc != 0) return false

      const val = res.returnvalue
      this.page.total = val.recordcount

      if (!val.recordlist) this.bookList = []
      else
        this.bookList = val.recordlist.map((item, index) => {
          item.index = index
          if (item.dmcode == '电子书') {
            item.dmcode = '1987052001'
          } else if (item.dmcode == '纸书') {
            item.dmcode = '1987051001'
          } else {
            item.dmcode = ''
          }
          const buttons = item.recordaction
          if (buttons) {
            item.recordaction = buttons.map((btn) => {
              btn.type = btn.text.includes('评论') ? 'primary' : 'plain'
              return btn
            })
          }
          return item
        })

      this.subjectGroup = val.classifygroup
      this.orderActions = val.orderactions
      this.leftData = val.left
        .map((item) => {
          item.newArrays = []
          return item
        })
        .filter((item) => item.items.length > 0)

      this.loading = false
    },
    getParams(obj) {
      // debugger
      let param = ''
      let idx = 0
      for (let key in obj) {
        if ((!obj[key] && obj[key] != 0) || key == 'total') continue
        let dot = idx === 0 ? '?' : '&'
        param += dot + (key + '=' + obj[key])
        idx += 1
      }

      let str = `&collectionruid=${this.$route.query.id}`
      param += str
      return param
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  /deep/ .container_left {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
  .list-container {
    border: none;
    padding: 20px;
    width: 100%;
    // margin-left: 10px;
    background: #fff;
    border: 1px solid #e6e6e6;
    min-width: 0;
    > * {
      margin-bottom: 20px;
    }
    .list-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #eee;
      padding: 15px;
      .total-count {
        color: #f00;
        margin: 0 5px;
      }
      .el-button {
        margin-left: 20px;
      }
    }
    .sort-search {
      display: flex;
      justify-content: space-between;
    }
    .no-content {
      height: 500px;
    }
    .item-list {
      .item-box {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #e6e6e6;
        .left-box {
          width: 60px;
          text-align: center;
          p {
            font-size: 1.2rem;
            margin-bottom: 5px;
          }
          /deep/ .el-checkbox__label {
            display: none;
          }
        }
        .detail {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .buttons {
            width: 120px;
            min-width: 120px;
            margin-left: 20px;
            button {
              width: 100%;
              margin: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
.left {
  margin-right: 20px;
  width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  background: #eee;
  /deep/ .element {
    background: #fff;
    padding: 10px;
    > p {
      padding: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
    }
    .tag-box {
      background-color: #fff;
    }
  }
}
</style>
